<template>
  <div class="content7">
    <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
      <audio :src="audio1Src" ref='audio1'></audio>
      <audio :src="audio2Src" ref='audio2'></audio>
      <audio :src="audio3Src" ref='audio3'></audio>
      <audio :src="audio4Src" ref='audio4'></audio>
    </div>
    <div class="main-content">
      <div class="card">
        <img src="../assets/img/gif7.gif" alt="" />
        <div class="template7-info-content" id="template7-1">
          <div>{{model_chart_name1}}</div>
          <div v-html="chart_data_1"></div>
        </div>
        <div class="template7-info-content none" id="template7-2">
          <div id="template7-2-1">
            <div class="template7-title">{{model_chart_name2}}</div>
            <div v-html="chart_data_2"></div>
          </div>
        </div>
        <div class="template7-info-content none" id="template7-3">
          <div>{{model_chart_name3}}</div>
          <div v-html="chart_data_3"></div>
        </div>
        <div class="template7-info-content none" id="template7-4">
          <div>{{model_chart_name4}}</div>
          <div v-html="chart_data_4"></div>
        </div>
        <div class="template7-bottom-content">
          <div>{{extra_param_1}}</div>
        </div>
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card2">
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/end.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams } from "../utils/common";
import ApiServe from '@/api/index'
export default {
  name: "Template7",
  data() {
    return {
      device_id:null,
      extra_param_1:'',
      chart_data_1:'',
      model_chart_name1:'',
      chart_data_2:'',
      model_chart_name2:'',
      chart_data_3:'',
      model_chart_name3:'',
      chart_data_4:'',
      model_chart_name4:'',
      audioData:[],
      audio1Src:'',
      audio2Src:'',
      audio3Src:'',
      audio4Src:'',
      totalRecordTime:null
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { chart_data_1, audioData } = this;
      return {
        chart_data_1,
        audioData,
      };
    },
  },
  watch: {
    allReady(val) {
      console.log(val);
      if (val.chart_data_1  && val.audioData.length>0) {
        if (this.device_id) {
          setTimeout(() => {
            ApiServe.recordStart({
                model_id: 7,
                device_id: this.device_id,
                record_time: this.totalRecordTime,
                real: 1,
              }).then((res)=>{
                this.load();
              })
          }, 5000);
        } else {
          setTimeout(()=>{
            this.load();
          },3000)
        }
      }
    },
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 7 }).then(({data,code})=>{
        this.chart_data_1 = data.chart_data_1[0].data['早盘表现'][0]
        this.model_chart_name1 = data.chart_data_1[0].model_chart_name
        this.chart_data_2 = data.chart_data_2[0].data['午后操盘'][0]
        this.model_chart_name2 = data.chart_data_2[0].model_chart_name
        this.chart_data_3 = data.chart_data_3[0].data['热门题材'][0]
        this.model_chart_name3 = data.chart_data_3[0].model_chart_name
        this.chart_data_4 = data.chart_data_4[0].data['要闻速递'][0]
        this.model_chart_name4 = data.chart_data_4[0].model_chart_name
        this.extra_param_1 = data.extra_param_1[0]
      })  
    },
    // 获取旁白
    getAudio() {
      ApiServe.getVideoData({ model_id: 7 }).then(({data,code})=>{
        this.audioData = data || []
        this.audio1Src = data[0].audio_link;
        this.audio2Src = data[1].audio_link;
        this.audio3Src = data[2].audio_link;
        this.audio4Src = data[3].audio_link;
        // 视频录制时间  4秒间隔，5秒尾部动画
        this.totalRecordTime = 4 + 5 + data.reduce((pre,cur)=>{
          return pre + cur.scene_duration
        },0) 
      })
    },
    //午后操盘的数据太多往上平移
    moveToTop(time){
      let domHeight1 = $('#template7-2').height();
      let domHeight2 = $('#template7-2-1').height();
      if(domHeight2>domHeight1){
        // let space = domHeight2 - domHeight1 + 16
        $('#template7-2-1').animate({bottom:0},time)
      }
    }, 
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      const scene_duration1 = this.audioData[0].scene_duration
      const scene_duration2 = scene_duration1 + 1 + this.audioData[1].scene_duration
      const scene_duration3 = scene_duration2 +1+ this.audioData[2].scene_duration
      const scene_duration4 = scene_duration3+1+ this.audioData[3].scene_duration
      this.$refs.audio1.play()
      setTimeout( ()=> {
        $("#template7-1").addClass("animate__fadeOut");
      }, scene_duration1*1000);
      setTimeout(() => {
        $("#template7-1").addClass("none");
        $("#template7-2").removeClass("none");
        this.$refs.audio2.play()
        this.moveToTop(this.audioData[1].scene_duration*1000)
      }, (scene_duration1+1)*1000);          //间隔
      setTimeout( ()=> {
        $("#template7-2").addClass("animate__fadeOut");
      }, scene_duration2*1000);
      setTimeout( ()=> {
        $("#template7-2").addClass("none");
        $("#template7-3").removeClass("none");
        this.$refs.audio3.play()
      }, (scene_duration2+1)*1000);     //间隔
      setTimeout( ()=> {
        $("#template7-4").addClass("animate__fadeOut");
      }, (scene_duration3)*1000);
      setTimeout( ()=> {
        $("#template7-3").addClass("none");
        $("#template7-4").removeClass("none");
        this.$refs.audio4.play()
      }, (scene_duration3+1)*1000);   //间隔
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, scene_duration4*1000);
      setTimeout( ()=> {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.endVideo.play()
      }, (scene_duration4+1)*1000);
      // 总时间scene_duration4+1+5秒
      // 5秒最后一页视频，总时长外再等待5秒调取录屏结束服务接口
      if(this.device_id){
        setTimeout( ()=> {
          ApiServe.recordEnd({model_id:7,device_id:this.device_id,real:1})
        },(scene_duration4+1+5+5)*1000);   //间隔
      }
    },
  },
};
</script>
<style scoped>
.card img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
.content7,.card {
  height: 100%;
  background: #3b2675;
  color: #000;
}
.main-content{
  left: 0;
  right: 0;
}
.template7-info-content{
  position: absolute;
  left: 12%;
  right: 12%;
  top: 7%;
  height: 48%;
  overflow: hidden;
}
.template7-bottom-content{
  position: absolute;
  left: 12%;
  right: 12%;
  top: 71%;
}
#template7-2-1{
  position: absolute;
}
</style>